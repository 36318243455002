<template>
  <div class="app-wrap">
    <h3>资产证明申请</h3>
    <div class="asset-wrap">
      <div class="left">
        <h4>确认信息</h4>
        <div class="item">
          <div>用户姓名</div>
          <span>{{ userInfo.clientName }}</span>
        </div>
        <div class="item">
          <div>姓名拼音</div>
          <span>{{ userInfo.pinyin }}</span>
        </div>
        <div class="item">
          <div>发送到</div>
          <el-input
            v-model="email"
            style="margin-left:28px;width:280px"
            placeholder="请输入您的邮箱地址"
          />
        </div>
        <span
          class="send-btn"
          @click="handleSubmit"
        >提交申请</span>
      </div>
      <div class="right">
        <h4>申请须知</h4>
        <p>
          资产证明是一份具有电子签名的PDF文档，将以邮件附件形式发送到您的邮箱。<br>
          1.资产证明文件中暂不包含投顾资产。<br>
          2.资产证明常作为出国签证、银行贷款、财产纠纷、诉讼、税务稽查等事务 的证明材料，资产证明的法律效力以有关机关实际判断为准。<br>
          3.资产证明只表示该客户在证明开出之际我司业务系统记录的份额明细，该 客户实际拥有的资产情况以基金注册登记机构系统记录为准。<br>
          4.资产证明不能用于质押、抵押等担保用途，也不具备其他法律效用，且副本无效。对本资产证明书的任何内容的修改、涂改均使资产证明书无效。<br>
        </p>
        <div class="tips">
          如有疑问请联系客服热线：0851-82209888
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {assetProve} from "@/api/assetProve";
import { emailCheck } from '@/utils/checkReg.js'
export default {
  name: "AssetProve",
  components: {},
  data() {
    return {
      email: '',
      userInfo: {}
    }
  },
  mounted () {
    this.userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
  },
  methods: {
    handleSubmit() {
      if (!this.email) {
        this.$message({
          message: '邮箱不能为空',
          type: 'warning'
        });
        return
      }
      if (!emailCheck.test(this.email)) {
        this.$message({
          message: '邮箱不合法',
          type: 'warning'
        });
        return
      }
      assetProve({mailBox: this.email}).then(()=>{
        this.$message({
          message: '提交申请成功',
          type: 'success'
        });
      })
    },
  }
};
</script>

<style scoped lang="less">
@import url('@/assets/css/zc-common.less');
.app-wrap > h3 {
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  color: #1F1F1F;
  padding-bottom: 30px;
  border-bottom: 1px solid #EDF1F7;
}
.asset-wrap {
  width: 100%;
  margin-top: 40px;
  .flex(space-between, flex-start, row);
  .left {
    width: 438px;
    &>h4 {
      font-size: 18px;
      font-weight: bold;
      color: #1F1F1F;
      margin-bottom: 50px;
    }
    .item {
      width: 100%;
      margin-bottom: 36px;
      .flex(flex-start, center, row);
      & > div {
        width: 158px;
        font-size: 16px;
        color: #A5A5A5;
      }
      &>span {
        margin-left: 28px;
        font-size: 14px;
        color: #25293D;
      }
    }
    .send-btn {
      margin-top: 8px;
      .btn(150px, 46px);
    }
  }
  .right {
    width: 344px;
    background: #FFFAF4;
    padding: 20px 16px;
    & > h4 {
      font-size: 18px;
      font-weight: bold;
      color: #CE9B63;
      margin-bottom: 30px;
    }
    p {
      font-size: 12px;
      color: #744B1D;
      line-height: 20px;
    }
    .tips {
      margin-top: 30px;
      font-size: 14px;
      color: #C5231C;
    }
  }
}
</style>